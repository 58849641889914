import '@fontsource/raleway';
import '@fontsource/raleway/700.css';
import 'stylesheets/application.scss';
import '@fortawesome/fontawesome-free/css/all.css';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
// const imagePath = (name) => images(name, true)
